import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Translation } from '../models/translations.model';

@Injectable({
  providedIn: 'root',
})
export class CoreStateService {
  translation$ = new BehaviorSubject<Translation>(null);

  constructor() {}
}
