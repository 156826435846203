import { provideState, provideStore } from '@ngrx/store';
import { companyReducer } from './core/store/company/company.reducer';
import { companyFeatureKey } from './core/store/company/company.selector';
import { provideEffects } from '@ngrx/effects';
import { CompanyEffects } from './core/store/company/company.effects';
import { MapEffects } from './core/store/map/map.effects';
import { mapFeatureKey } from './core/store/map/map.selector';
import { mapReducer } from './core/store/map/map.reducer';
import { ProgramEffects } from './core/store/program/program.effects';
import { programFeatureKey } from './core/store/program/program.selector';
import { programReducer } from './core/store/program/program.reducer';
import { adminFeatureKey } from './core/store/admin/admin.selector';
import { adminReducer } from './core/store/admin/admin.reducer';
import { AdminEffects } from './core/store/admin/admin.effects';
import { routeMapFeatureKey } from './core/store/route-map/route-map.selector';
import { routeMapReducer } from './core/store/route-map/route-map.reducer';
import { RouteMapEffects } from './core/store/route-map/route-map.effects';
import { schemeFeatureKey } from './core/store/scheme/scheme.selector';
import { schemeReducer } from './core/store/scheme/scheme.reducer';
import { SchemeEffects } from './core/store/scheme/scheme.effects';
import { inductionLocationFeatureKey } from './core/store/induction-location/induction-location.selector';
import { inductionLocationReducer } from './core/store/induction-location/induction-location.reducer';
import { InductionLocationEffects } from './core/store/induction-location/induction-location.effects';
import { userFeatureKey } from './core/store/user/user.selector';
import { UserReducer } from './core/store/user/user.reducer';
import { UserEffects } from './core/store/user/user.effects';
import { AddressEffects } from './core/store/address/address.effects';
import { addressFeatureKey } from './core/store/address/address.selector';
import { addressReducer } from './core/store/address/address.reducer';
import { constraintFeatureKey } from './core/store/constraints/constraint.selector';
import { constraintReducer } from './core/store/constraints/constraint.reducer';
import { ConstraintEffects } from './core/store/constraints/constraint.effects';
import { contactFeatureKey } from './core/store/contact/contact.selector';
import { contactReducer } from './core/store/contact/contact.reducer';
import { ContactEffects } from './core/store/contact/contact.effects';
import { originFeatureKey } from './core/store/origin/origin.selector';
import { originReducer } from './core/store/origin/origin.reducer';
import { OriginEffects } from './core/store/origin/origin.effects';
import { programOriginFeatureKey } from './core/store/program-origin/program-origin.selector';
import { programOriginReducer } from './core/store/program-origin/program-origin.reducer';
import { ProgramOriginEffects } from './core/store/program-origin/program-origin.effects';
import { routeFeatureKey } from './core/store/route/route.selector';
import { routeReducer } from './core/store/route/route.reducer';
import { RouteEffects } from './core/store/route/route.effects';
import { elementFeatureKey } from './core/store/element/element.selector';
import { elementReducer } from './core/store/element/element.reducer';
import { ElementEffects } from './core/store/element/element.effects';
import { elementValueFeatureKey } from './core/store/element-value/element-value.selector';
import { elementValueReducer } from './core/store/element-value/element-value.reducer';
import { ElementValuesEffects } from './core/store/element-value/element-value.effects';
import { schemeTypesFeatureKey } from './core/store/scheme-types/scheme-types.selector';
import { schemeTypesReducer } from './core/store/scheme-types/scheme-types.reducer';
import { SchemeTypesEffects } from './core/store/scheme-types/scheme-types.effects';

export const storeImports = [
  provideStore(),
  provideState({ name: companyFeatureKey, reducer: companyReducer }),
  provideState({ name: mapFeatureKey, reducer: mapReducer }),
  provideState({ name: programFeatureKey, reducer: programReducer }),
  provideState({ name: adminFeatureKey, reducer: adminReducer }),
  provideState({ name: routeMapFeatureKey, reducer: routeMapReducer }),
  provideState({ name: schemeFeatureKey, reducer: schemeReducer }),
  provideState({ name: inductionLocationFeatureKey, reducer: inductionLocationReducer }),
  provideState({ name: userFeatureKey, reducer: UserReducer }),
  provideState({ name: addressFeatureKey, reducer: addressReducer }),
  provideState({ name: constraintFeatureKey, reducer: constraintReducer }),
  provideState({ name: contactFeatureKey, reducer: contactReducer }),
  provideState({ name: originFeatureKey, reducer: originReducer }),
  provideState({ name: programOriginFeatureKey, reducer: programOriginReducer }),
  provideState({ name: routeFeatureKey, reducer: routeReducer }),
  provideState({ name: elementFeatureKey, reducer: elementReducer }),
  provideState({ name: elementValueFeatureKey, reducer: elementValueReducer }),
  provideState({ name: schemeTypesFeatureKey, reducer: schemeTypesReducer }),
  provideEffects(
    CompanyEffects,
    MapEffects,
    ProgramEffects,
    AdminEffects,
    RouteMapEffects,
    SchemeEffects,
    InductionLocationEffects,
    UserEffects,
    AddressEffects,
    ConstraintEffects,
    ContactEffects,
    OriginEffects,
    ProgramOriginEffects,
    RouteEffects,
    ElementEffects,
    ElementValuesEffects,
    SchemeTypesEffects
  ),
];
