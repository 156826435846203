import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../store/user/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private http: HttpClient) {}

  login(loginInfo: LoginInfo): Observable<LoginResp> {
    return this.http.post<LoginResp>(`${environment.apiRoutes.auth}login`, loginInfo);
  }
}

export interface LoginInfo {
  email: string;
  password: string;
}

export interface LoginResp {
  token: string;
  user: User;
}
