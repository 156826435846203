import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, map, Observable, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { CoreStateService } from './core/services/core-state.service';
import { inject, Injectable } from '@angular/core';

type TranslationDTO = {
  id: number;
  lang: string;
  orgName: string;
  value: JSON;
};

@Injectable({
  providedIn: 'root',
})
export class TranslateHttpLoader implements TranslateLoader {
  private coreStateService = inject(CoreStateService);

  public prefix: string = '/assets/i18n/';
  public suffix: string = '.json';

  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): Observable<Object> {
    return this.coreStateService.translation$.pipe(map(translation => translation?.value));
  }

  loadTranslations(lang: string): Observable<Object> {
    const ORG_NAME = environment.orgName;
    const endpoint = environment.apiRoutes.translations;

    return this.http.get<TranslationDTO>(`${endpoint}organization/${ORG_NAME}/lang/${lang}`).pipe(
      tap(res => {
        this.coreStateService.translation$.next(res);
      }),
      catchError((): Observable<File> => {
        return this.http.get<File>(`${this.prefix}${lang}${this.suffix}`);
      })
    );
  }
}
