import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { IconsService } from './core/services/icons.service';
import { OnboardrSpinnerComponent } from './shared/components/onboardr-spinner/onboardr-spinner.component';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from './app.translations';
import { Translation } from './core/models/translations.model';
import { CoreStateService } from './core/services/core-state.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, OnboardrSpinnerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private coreStateService = inject(CoreStateService);
  iconsService = inject(IconsService);
  translate = inject(TranslateService);

  constructor(private translateLoader: TranslateHttpLoader) {
    this.loadAppIcons();
    this.setTranslateConfig();
  }

  setTranslateConfig(): void {
    this.translateLoader.loadTranslations('en').subscribe(data => {
      if (data?.['id']) {
        const translation = data as Translation;
        this.coreStateService.translation$.next(translation);
        this.translate.addLangs([translation.lang]);
        this.translate.setDefaultLang(translation.lang);
        this.translate.reloadLang(translation.lang);
        this.translate.use(translation.lang);
      } else {
        const translation = data as JSON;
        this.coreStateService.translation$.next({
          id: 1,
          lang: 'en',
          orgName: 'onboardr',
          value: translation,
        } as Translation);
        this.translate.setDefaultLang('en');
        this.translate.reloadLang('en');
        this.translate.use('en');
      }
    });
  }

  loadAppIcons(): void {
    this.iconsService.loadAngularSvgIcons();
    this.iconsService.loadMaterialCustomIcons();
  }
}
